import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { BsFillEyeFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import eyeIcon from "../../assets/ic-hide.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import eyehide from "../../assets/ic-hide.png";
import eyeShow from "../../assets/vector (3).png";
import LoaderSpinner from "../Loader/Loader";
import axios from "axios";
import APIURL from "../../component/Utils/api-urls";
import { useEffect } from "react";
import { successMessage } from "../Utils/ToasterMessage";
import { errorMessage } from "../Utils/ToasterMessage";
function MyVerticallyCenteredModal(props) {
  const [error, seterror] = useState(false);
  const [useremail, setuseremail] = useState("");
  const [pass, setpass] = useState("");
  const [repass, setrepass] = useState("");
  const [patientName, setpatientName] = useState("Rohit Sharma");
  const [role, setRole] = useState("patient");
  const [changePassword, setChangePassword] = useState(true);
  const [changeRePassword, setChangeRePassword] = useState(true);

  const changeIcon = changePassword === true ? false : true;
  const rechangeIcon = changeRePassword === true ? false : true;

  const addPatient = async (e) => {
    e.preventDefault();
    let body = {
      email: useremail,
      password: pass,
      name: patientName,
      role: role,
    };
    await axios
      .post(`${APIURL.addPatient}`, body, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
      .then((res) => {
        console.log(res);
        successMessage("Patient Added Suceessfully ");
      })
      .catch((err) => {
        errorMessage(err.response.data.message);
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <form>
          <div className="add_patient text-center py-3">Add Patient</div>

          <div className="mt-2">
            <input
              type="email"
              className="login-input"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter your email address"
              value={useremail}
              onChange={(e) => {
                const emailValidate =
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                let flag = emailValidate.test(e.target.value);

                console.log(flag);
                setuseremail(e.target.value);

                if (flag === false) {
                  seterror(true);
                } else {
                  seterror(false);
                }
              }}
            />

            <div className="invalid-feedback">
              Please enter a valid Email adddress!
            </div>
            {error ? (
              <span style={{ color: "red" }}>Enter email is invalid</span>
            ) : (
              ""
            )}
          </div>
          <div className="pt-4">
            <input
              type={changePassword ? "password" : "text"}
              className="login-input"
              id="exampleInputPassword1"
              placeholder="Enter your password"
              value={pass}
              onChange={(e) => {
                setpass(e.target.value);
              }}
            />
            <span
              className="mr-l position-absolute float-end"
              onClick={() => {
                setChangePassword(changeIcon);
              }}
            >
              {changeIcon ? (
                <img src={eyehide} alt="Eye" />
              ) : (
                <img src={eyeShow} alt="EyeShow" />
              )}
            </span>
          </div>
          <div className="py-4">
            <input
              type={changeRePassword ? "password" : "text"}
              className="login-input"
              id="exampleInputPassword1"
              placeholder="Re-Enter Your Password"
              value={repass}
              onChange={(e) => {
                setrepass(e.target.value);
              }}
            />
            <span
              className="mr-l position-absolute float-end"
              onClick={() => {
                setChangeRePassword(rechangeIcon);
              }}
            >
              {rechangeIcon ? (
                <img src={eyehide} alt="Eye" />
              ) : (
                <img src={eyeShow} alt="EyeShow" />
              )}
            </span>
          </div>

          <button
            type="submit"
            className="btn btn_bg btn_confirm text-white w-100 mb-4 mt-2"
            disabled={useremail === "" || pass == "" || repass === ""}
            onClick={addPatient}
          >
            Send Link
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

function Table2() {
  const [modalShow, setModalShow] = React.useState(false);
  const [search, setSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [patient, setPatient] = useState([]);
  const [filterData, setfilterdata] = useState([]);
  const navigate = useNavigate("");
  const getList = async () => {
    setIsLoading(true);

    await axios
      .get(`${APIURL.patientList}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setPatient(res.data.results);
        setfilterdata(res.data.results);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  async function singleDetails(row) {
    navigate("/patient-detail", { state: { DATA: row } });
    console.log(row, "single");
  }
  const columns = [
    {
      name: "Patient Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Address",
      selector: (row) => row.address.city,
    },
    {
      name: "Email",
      selector: (row) => row.emailAddress,
    },
    {
      name: "Phone Number",
      selector: (row) => row.additionalMobileNumber,
    },
    // {
    //   name: "Uploaded Report",
    //   selector: (row) => {
    //     let date = "abc.pdf";
    //     return date;
    //   },
    // },

    {
      name: "Action",
      selector: (row) => {
        return (
          <img
            src={eyeIcon}
            alt=""
            onClick={() => {
              singleDetails(row);
            }}
          />
        );
      },
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Show",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    // selectAllRowsItemText: 'Todos',
  };
  const customStyles = {
    rows: {
      style: {
        minHeight: "46px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "22px",
        // textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "19px",
        textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
  };
  const data = [
    {
      registration: 1,
      pharmacyname: "Conan the Barbarian",
      location: "indai",
      email: "1982",
      regdate: "1982",
      status: "1982",
      location: "1982",
      action: "1982",
    },
    {
      registration: 1,
      pharmacyname: "Conan the Barbarian",
      location: "indai",
      email: "1982",
      regdate: "1982",
      status: "1982",
      location: "1982",
      action: "1982",
    },
  ];

  useEffect(() => {
    if (search) {
      let filterddata = patient.filter((mapping) => {
        return (
          String(mapping?.firstName)
            .toLowerCase()
            .includes(String(search).toLowerCase()) ||
          String(mapping?.address.city)
            .toLowerCase()
            .includes(String(search).toLowerCase()) ||
          String(mapping?.emailAddress)
            .toLowerCase()
            .includes(String(search).toLowerCase()) ||
          String(mapping?.additionalMobileNumber)
            .toLowerCase()
            .includes(String(search).toLowerCase())
        );
      });
      setfilterdata(filterddata);
    } else {
      setfilterdata(patient);
    }
  }, [search]);

  useEffect(() => {
    getList();
  }, [reloadPage]);

  return (
    <>
      <div className="container-fluid">
        <div className="row py-4 px-2">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div class="card-header bg-white">
                <div className="float-start">
                  <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item ">
                        <a href="#" className="bread-high">
                          Patient Management
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="float-end">
                  <button
                    className="border-btn px-5 bg-white font_size me-2 btn_cancel"
                    onClick={() => setModalShow(true)}
                  >
                    Add Patient
                  </button>
                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                  <button className="border-btn px-5 bg_btn text-white font_size">
                    Download
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <DataTable
                      columns={columns}
                      data={filterData}
                      customStyles={customStyles}
                      progressPending={isLoading}
                      progressComponent={<LoaderSpinner />}
                      paginationPerPage={50}
                      paginationRowsPerPageOptions={[50, 100, 200, 500]}
                      pagination
                      paginationComponentOptions={{
                        rowsPerPageText: "Records per page:",
                        rangeSeparatorText: "out of",
                      }}
                      fixedheader
                      fixedheaderscrollheight="400px"
                      highlightOnHover
                      subHeader
                      subHeaderComponent={
                        <input
                          type="text"
                          placeholder="Search here"
                          className="input-width form-control"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Table2;
