import React, { Fragment, useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Datatab1 from "../Table/Table1";
import "./PharmacyManagment.css";
// import Modal from "react-bootstrap/Modal";
import eyehide from "../../assets/ic-hide.png";
import eyeShow from "../../assets/vector (3).png";
import {
  Card,
  CardBody,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

function PharmacyManagment() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <Fragment>
        <div className="container-fluid">
          <div className="row  py-4 px-2">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div class="card-header bg-white">
                  <div className="float-start">
                    <nav aria-label="breadcrumb ">
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item ">
                          <a className="bread-high">User list</a>
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="float-end">
                    {/* <button
                      className="border-btn px-5 bg-white font_size me-2 btn_cancel"
                      data-bs-toggle="modal"
                      data-bs-target="#deletemember"
                      onClick={toggle}
                    >
                      Add Pharmacy
                    </button>

                    <button className="border-btn px-5 bg_btn text-white font_size">
                      Download
                    </button> */}
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <Datatab1 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalFooter className="justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col">
                  <form>
                    <div className="add_patient text-center py-3">
                      Add Patient
                    </div>

                    <div class="mt-2">
                      <input
                        type="email"
                        className="login-input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter your email address"
                        onChange={(e) => {
                          const emailValidate =
                            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                          let flag = emailValidate.test(e.target.value);

                          // console.log(flag);
                          // setuseremail(e.target.value);

                          // if (flag === false) {
                          //   seterror(true);
                          // } else {
                          //   seterror(false);
                          // }
                        }}
                      />
                    </div>
                    <div class="pt-4">
                      <input
                        type="text"
                        className="login-input"
                        id="exampleInputPassword1"
                        placeholder="Enter your password"
                      />
                    </div>
                    <div className="py-4">
                      <input
                        type="text"
                        className="login-input"
                        id="exampleInputPassword1"
                        placeholder="Re-Enter Your Password"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn_bg btn_confirm text-white w-100 mb-4 mt-2"
                    >
                      Send Link
                    </button>
                  </form>
                </div>
              </div>
            </div>

            {/* <span
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#deletemember"
              onClick={() => Remove(deleteID)}
            >
              <Button color="primary" onClick={toggle}>
                Delete
              </Button>{" "}
            </span>

            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button> */}
          </ModalFooter>
        </Modal>
      </Fragment>
    </>
  );
}
export default PharmacyManagment;
