/** @format */

import React, { useEffect, useState } from "react";
import Profilepic from "../../assets/profilepic.jpg";
import "./Pharmacy.css";
import APIURL from "../Utils/api-urls";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

export default function Pharmacydetails() {
  const location = useLocation();
  let viewdetails = location.state?.DATA;

  const [number, setnumber] = useState(viewdetails?.client_number);
  const [chatbotdata, setChatbotdata] = useState([]);

  console.log(
    number,
    "viewdetailsviewdetailsviewdetailsviewdetailsviewdetailsviewdetails"
  );

  useEffect(() => {
    axios
      .get(
        `${APIURL.details}/${number}`,

        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data?.data);
        setChatbotdata(res?.data?.data);
      });
  }, []);

  return (
    <div className="container-fluid">
      <div className="row py-4 px-2">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="card ">
            <div class="card-header bg-white">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item ">
                    <Link className="m-auto link-line" to="/user-managment">
                      <a className="bread-high">User Details</a>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    User Detail
                  </li>
                </ol>
              </nav>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <h3>Chatbot</h3>
                        <h3>User</h3>
                      </div>

                      {chatbotdata?.map((res) => {
                        let time = new Date(res?.createdAt);
                        let hours = time.getHours();
                        let minutes = time.getMinutes();
                        let formattedTime = `${hours}:${minutes}`;
                        // let formatedTime = time.split(12,20);
                        return (
                          <ul>
                            <li className={`${res.type}`} key={res.type}>
                              {
                                <p>
                                  <span>{res?.message}</span>
                                  <span>{formattedTime}</span>
                                </p>
                              }
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* {
      name: "Joining Date",
      selector: (row) => {
        let newDob = new Date(row?.createdAt);
        let formattedDateCal = newDob.toLocaleDateString("en-GB", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
        return formattedDateCal;
      },
    }, */}
                {/* <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>License Number</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: # 123456ABCD</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>Uploaded File</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <div className="row">
                              <div className="col-6">
                                <p>: Uploaded File</p>
                              </div>
                              <div className="col-md-6 col-lg-12 col-sm-6 col-xl-6 ">
                                <button className="border-btn px-4 bg_btn text-white font_size">
                                  View Document
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="card">
                      <div className="card-body ">
                        <h5>Location</h5>
                        <div className="row mt-3">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>Address Line 1</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: 7958 Cameron</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>Address Line 2</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: Brown Ct</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>Landmark</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: Brown Ct</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>Zip/Postal Code</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: 22153</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>City</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: Springfield</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row d-flex justify-content-end">
                {/* <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 ">
                  <button class="mt-4 px-4 ms-2 float-end border-btn bg-white font_size">
                    Approve
                  </button>
                  <button className="mt-4 px-4 float-end border-btn bg-white font_size">
                    Disapprove
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
