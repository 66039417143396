import React from "react";
import ReactDOM from "react-dom/client";
import Sidebar from "./component/Navbar/Sidebar";
import App from "./App";
import Pharmacydetails from "./component/Pharmacy Details/Pharmacydetails";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import reportWebVitals from "./reportWebVitals";
import PatientDetails from "./component/Patient Managment/PatientDetail";
import MedicalReport from "./component/Medical report/MedicalReport";
import InvoiceDetails from "./component/Invoice Detail/InvoiceDetail";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
    {/* <Pharmacydetails /> */}
    {/* <PatientDetails /> */}
    {/* <MedicalReport /> */}
    {/* <InvoiceDetails /> */}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
