import React, { useState } from "react";
import "./login.css";
import { Link } from "react-router-dom";
import axios from "axios";
import APIURL from "../Utils/api-urls";
import { useNavigate } from "react-router-dom";
import { successMessage } from "../Utils/ToasterMessage";
import { errorMessage } from "../Utils/ToasterMessage";
import eyehide from "../../assets/ic-hide.png";
import eyeShow from "../../assets/vector (3).png";
// import logoTransparent from "../../assets/logo-transparent.png";
import logoTransparent from "../../assets/new-images/Wirchat Logo variation 2 1 logo.png";

export default function login() {
  const navigate = useNavigate("");
  const [error, seterror] = useState(false);
  const [useremail, setuseremail] = useState("");
  const [pass, setpass] = useState("");
  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;
  const login = async (e) => {
    e.preventDefault();
    let body = {
      email: useremail,
      password: pass,
    };
    await axios
      .post(`${APIURL.login}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
         console.log(res.data.token);
         localStorage.setItem("auth", res?.data?.token);
        navigate("/user-managment");
        successMessage("Login Suceessfully ");
      })
      .catch((err) => {
        errorMessage(err.response.data.message);
      });
  };

  return (
    <div className="container-fluid login-bg">
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
          <div className="row  d-flex justify-content-center  align-items-center  login-card">
            <div className="col-md-5 col-lg-4 col-xl-4 col-sm-6 col-8 ">
              <p className="d-flex justify-content-center login-head ">
                <img src={logoTransparent} alt="" width="50%" />
              </p>
              <div className="card">
                <div className="card-body ">
                  <p className="d-flex justify-content-center login-text">
                    Login to your account
                  </p>
                  {/* <p className="d-flex justify-content-center ">
                    Don’t have an account ?{" "}
                    <Link style={{ textDecoration: "none" }} to="/SignUp">
                      Sign Up Here
                    </Link>
                  </p> */}

                  <form>
                    <div className="mb-3 pt-3">
                      <input
                        type="email"
                        className="login-input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter your email address"
                        required
                        maxLength="20"
                        value={useremail}
                        onChange={(e) => {
                          const emailValidate =
                            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                          let flag = emailValidate.test(e.target.value);

                          // console.log(flag);
                          setuseremail(e.target.value);

                          if (flag === false) {
                            seterror(true);
                          } else {
                            seterror(false);
                          }
                        }}
                      />
                    </div>
                    <div className="invalid-feedback">
                      Please enter a valid Email adddress!
                    </div>
                    {error ? (
                      <span style={{ color: "red" }}>
                        Enter email is invalid
                      </span>
                    ) : (
                      ""
                    )}
                    <div className="mb-3 pt-3">
                      <input
                        type={changePassword ? "password" : "text"}
                        className="login-input position-relative"
                        id="exampleInputPassword1"
                        placeholder="Enter your password"
                        onChange={(e) => {
                          setpass(e.target.value);
                        }}
                      />
                      <span
                        className="mr-l position-absolute float-end"
                        onClick={() => {
                          setChangePassword(changeIcon);
                        }}
                      >
                        {changeIcon ? (
                          <img src={eyehide} alt="Eye" />
                        ) : (
                          <img src={eyeShow} alt="EyeShow" />
                        )}
                      </span>
                    </div>
                    <div className="mb-5 pt-3 form-check d-flex justify-content-between ">
                      <div>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label className="form-check-label" for="exampleCheck1">
                          Remember Me
                        </label>
                      </div>
                      <div>
                        <Link style={{ textDecoration: "none" }}>
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn_confirm btn_bg w-100 mb-4 text-white"
                      onClick={login}
                      disabled={useremail === "" || pass === ""}
                    >
                      Log In
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
