/** @format */

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import eyeIcon from "../../assets/ic-hide.png";
import LoaderSpinner from "../Loader/Loader";
import "./Table.css";
import APIURL from "../Utils/api-urls";
import axios from "axios";

function Table1() {

  const [search, setSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setuser] = useState([]);


  const navigate = useNavigate("");


  async function Redirect(row) {
    navigate(`/table1/pharmacy-detail`, {
      state: { DATA: row },
    });
  }

  const getList = async () => {
    // setIsLoading(true);

    await axios
      .get(`${APIURL.userlist}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setIsLoading(false);

        setuser(res.data.data);
        // setfilterdata(res.data.results);
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  const columns = [
    {
      name: "Client_number",
      selector: (row) => row?.client_number,
    },
    {
      name: "Type",
      selector: (row) => row?.type,
    },

    
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex align-item-center">
          <p>
            {/* <i
              className="bi bi-pencil-square"
              title="Edit"
              style={{
                color: "#6362e7",
                fontSize: "18px",
                flexGrow: "0",
                cursor: "pointer",
                title: "Country Data",
              }}
              onClick={() => {
                Redirect(row);
              }}
            ></i> */}
            <img src={eyeIcon} alt=""
             onClick={() => {
                Redirect(row);
              }}
             />
          </p>
        
        </div>
      ),
    },

    // {
    //   name: "Action",
    //   selector: (row) => {
    //     return (
    //       <Link to="/table1/pharmacy-detail">
    //         {" "}
    //         <img src={eyeIcon} alt="" />
    //       </Link>
    //     );
    //   },
    // },
  ];

  // const paginationComponentOptions = {
  //   rowsPerPageText: "Show",
  //   rangeSeparatorText: "of",
  //   selectAllRowsItem: true,
  //   // selectAllRowsItemText: 'Todos',
  // };
  const customStyles = {
    rows: {
      style: {
        minHeight: "46px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "22px",
        // textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "19px",
        textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
  };

  useEffect(() => {
    getList();
  }, []);
  return (
    <>
      <DataTable
        columns={columns}
         data={user}
        customStyles={customStyles}
        progressPending={isLoading}
        progressComponent={<LoaderSpinner />}
        paginationPerPage={50}
        paginationRowsPerPageOptions={[50, 100, 200, 500]}
        pagination
        paginationComponentOptions={{
          rowsPerPageText: "Records per page:",
          rangeSeparatorText: "out of",
        }}
        fixedheader
        fixedheaderscrollheight="400px"
        highlightOnHover
        subHeader
        // subHeaderComponent={
        //   <input
        //     type="text"
        //     placeholder="Search here"
        //     className="input-width form-control"
        //     value={search}
        //     onChange={(e) => setSearch(e.target.value)}
        //   />
        // }
      />
    </>
  );
}
export default Table1;
