// const UserAuthUrl = "http://54.83.91.140:4000";
// const UserAuthUrl = "http://44.212.244.73:4000";
const UserAuthUrl = "https://backend.wirchat.com";
export const APIURL = {
  // login: `${UserAuthUrl}/v1/auth/login`,
login: `${UserAuthUrl}/product-service/v1/auth/login`,

  signup: `${UserAuthUrl}/v1/auth/register`,
  // patientList: `${UserAuthUrl}/v1/users/`,


  userlist: `${UserAuthUrl}/product-service/v1/bot/getALLInitiateNumbers`,

  details : `${UserAuthUrl}/product-service/v1/bot/getALLChatByNumber`,

  patientDetail: `${UserAuthUrl}/v1/users`,
  logOut: `${UserAuthUrl}/v1/users`,
  addPatient: `${UserAuthUrl}/v1/users`,
};
export default APIURL;



