import React from 'react'
import "../login/login.css"
import { Link } from 'react-router-dom'


export default function SignUp() {
    return (
        <div className='container-fluid login-bg' >
            <div className='row'>
          
                <div className='col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12'>
                    <div className='row  d-flex justify-content-center    login-card'>
                        <div className='col-md-5 col-lg-4 col-xl-4 col-sm-6 col-8'>
                        <p className='d-flex justify-content-center login-head pt-5'>MedSync</p>
                            <div className="card">
                                <div className="card-body ">
                                    <p className='d-flex justify-content-center login-text mt-3'>Your account is Registered</p>
                                    <p className='d-flex justify-content-center text-center mt-5'>We Successfully Sent Account activation Link <br/>to Your Registered Email Address</p>

                                    <button type="submit" className="btn btn-primary w-100 mb-2 mt-4">Back to Log In</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
