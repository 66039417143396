import React from "react";
import "./Loader.css";

export default function LoaderSpinner() {
  return (
    // <div className="spinner-container">
    //   <div className="loading-spinner">
    <div className="loaderfaltu"></div>

    //   </div>
    // </div>
  );
}
