import React, { useEffect, useState } from "react";
import Profilepic from "../../assets/profilepic.jpg";
import "./PatientDetail.css";
import Datatab3 from "../Table/Table3";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import APIURL from "../../component/Utils/api-urls";

export default function PatientDetails() {
  const navigate = useNavigate("");
  const location = useLocation("");
  let editsymbol = location.state?.DATA._id;
  console.log(editsymbol);

  const [singleId, setSingleId] = useState(editsymbol);
  const [singleDetail, setSingleDetail] = useState();

  // setSingleId(editsymbol);

  const getSingleData = async () => {
    await axios
      .get(`${APIURL.patientDetail}/${singleId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setSingleDetail(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getSingleData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row py-4 px-2">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header bg-white">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item ">
                    <Link className="m-auto link-line" to="/pharmacy-managment">
                      <a className="bread-high">Patient Management</a>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Patient Detail
                  </li>
                </ol>
              </nav>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row d-flex justify-content-center">
                        <img
                          src={Profilepic}
                          style={{ width: "17%", height: "50px" }}
                        />
                      </div>
                      <h6 className="text-center mt-5">XYZ Pharmacy</h6>
                      <h5>Contact Details</h5>
                      <div className="row mt-4">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <h6>Email Address</h6>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <p>: {singleDetail?.emailAddress}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <h6>Phone Number</h6>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <p>: {singleDetail?.additionalMobileNumber}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <h6>Registration ID</h6>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <p>: # 1254125412344</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-8 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                  <div className="row">
                    <div className="card">
                      <div className="card-body mb-5">
                        <h5>Location</h5>
                        <div className="row mt-4">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>Address Line 1</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: {singleDetail?.address.area}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>Address Line 2</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: {singleDetail?.address?.colony}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>Landmark</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: {singleDetail?.address?.colony}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>Zip/Postal Code</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: {singleDetail?.address?.pincode}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>City</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: {singleDetail?.address?.city}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                            <h6>Country</h6>
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7">
                            <p>: {singleDetail?.address?.country}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="head-list my-4">List of Uploaded Report</div>
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <Datatab3 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
