import React, { useState } from "react";
import "./Register.css";
import { Link } from "react-router-dom";
import axios from "axios";
import APIURL from "../Utils/api-urls";
import { successMessage, errorMessage } from "../Utils/ToasterMessage";
import { useNavigate } from "react-router-dom";

export default function RegisterConfirm() {
  const navigate = useNavigate("");
  return (
    <div className="container-fluid login-bg">
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
          <div className="row  d-flex justify-content-center  align-items-center  login-card">
            <div className="col-md-5 col-lg-4 col-xl-4 col-sm-6 col-8">
              <p className="d-flex justify-content-center login-head">
                MedSync
              </p>
              <div className="card">
                <div className="card-body ">
                  <p className="d-flex justify-content-center register-text mt-4">
                    Your account is Registered
                  </p>
                  <p className="d-flex justify-content-center text-center mt-4">
                    We Successfully Sent Account activation Link to Your
                    Registered Email Address
                  </p>

                  <form>
                    <div className="mt-4">
                      <Link to="/">
                        {" "}
                        <button
                          type="submit"
                          className="btn btn_bg text-white w-100 mb-4 mt-2 btn_confirm"
                        >
                          Back to Log In
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
