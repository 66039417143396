import React, { useState } from "react";
import "../login/login.css";
import { Link } from "react-router-dom";
import axios from "axios";
import APIURL from "../Utils/api-urls";
import { successMessage, errorMessage } from "../Utils/ToasterMessage";
import { useNavigate } from "react-router-dom";
import eyehide from "../../assets/ic-hide.png";
import eyeShow from "../../assets/vector (3).png";
export default function SignUp() {
  const navigate = useNavigate("");
  const [error, seterror] = useState(false);
  const [useremail, setuseremail] = useState("");
  const [pass, setpass] = useState("");
  const [repass, setrepass] = useState("");

  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;
  const signup = async (e) => {
    e.preventDefault();

    let body = {
      email: useremail,
      password: pass,
      role: "admin",
    };
    if (pass == repass) {
      await axios
        .post(`${APIURL.signup}`, body, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          navigate("/signup/confirm");
        })
        .catch((err) => {
          errorMessage(err.response.data.message);
        });
    } else {
      errorMessage("Password And Confirm Password Does not match");
    }
  };
  return (
    <div className="container-fluid login-bg">
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
          <div className="row  d-flex justify-content-center  align-items-center  login-card">
            <div className="col-md-5 col-lg-4 col-xl-4 col-sm-6 col-8">
              <p className="d-flex justify-content-center login-head">
                MedSync
              </p>
              <div className="card">
                <div className="card-body ">
                  <p className="d-flex justify-content-center login-text">
                    Register your account
                  </p>
                  <p className="d-flex justify-content-center ">
                    Already have an account ?{" "}
                    <Link style={{ textDecoration: "none" }} to="/">
                      Log in Here
                    </Link>
                  </p>

                  <form>
                    <div className="mb-3 pt-3">
                      <input
                        type="email"
                        className="login-input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter your email address"
                        value={useremail}
                        onChange={(e) => {
                          const emailValidate =
                            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                          let flag = emailValidate.test(e.target.value);

                          // console.log(flag);
                          setuseremail(e.target.value);

                          if (flag === false) {
                            seterror(true);
                          } else {
                            seterror(false);
                          }
                        }}
                      />
                    </div>
                    <div className="invalid-feedback">
                      Please enter a valid Email adddress!
                    </div>
                    {error ? (
                      <span style={{ color: "red" }}>
                        Enter email is invalid
                      </span>
                    ) : (
                      ""
                    )}
                    <div className="mb-3 pt-3">
                      <input
                        type={changePassword ? "password" : "text"}
                        className="login-input"
                        id="exampleInputPassword1"
                        placeholder="Enter your password"
                        value={pass}
                        onChange={(e) => {
                          setpass(e.target.value);
                        }}
                      />
                      <span
                        className="mr-l position-absolute float-end"
                        onClick={() => {
                          setChangePassword(changeIcon);
                        }}
                      >
                        {changeIcon ? (
                          <img src={eyehide} alt="Eye" />
                        ) : (
                          <img src={eyeShow} alt="EyeShow" />
                        )}
                      </span>
                    </div>
                    <div className="mb-3 pt-3">
                      <input
                        type="password"
                        className="login-input"
                        id="exampleInputPassword1"
                        placeholder="Re-Enter Your Password"
                        value={repass}
                        onChange={(e) => {
                          setrepass(e.target.value);
                        }}
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn_bg btn_confirm text-white w-100 mb-4 mt-2"
                      onClick={signup}
                      disabled={useremail === "" || pass == "" || repass === ""}
                    >
                      Register
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
