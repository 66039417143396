import React from "react";
import Reportpic from "../../assets/image 8.png";
import "./MedicalReport.css";
import Datatab4 from "../Table/Table4";
import { Link, useNavigate } from "react-router-dom";
import imgDelete from "../../assets/Delete-icon.png";
import imgAdd from "../../assets/Add-icon.png";
import Form from "react-bootstrap/Form";

export default function MedicalReport() {
  return (
    <div className="container-fluid">
      <div className="row py-4 px-2">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div class="card-header bg-white">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item ">
                    <Link className="m-auto link-line" to="/pharmacy-managment">
                      <a className="bread-high">Patient Management</a>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link className="m-auto link-line" to="/patient-detail">
                      Patient Detail
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Medical Report/Prescription
                  </li>
                </ol>
              </nav>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="row d-flex justify-content-center">
                        <img
                          src={Reportpic}
                          style={{ width: "100%", height: "360px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 mt-2">
                  <div className="row pb-3">
                    <div className="col-12">
                      <div className="Medical-text float-start">
                        Medical Report/Prescription
                      </div>
                      <div className="float-end">
                        <span className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                          <span className="upload_text">Uploaded Date</span>{" "}
                          <span className="date_text">: 08-05-2023</span>
                        </span>
                        <span className="col-xl-8 col-lg-8 col-md-8 col-sm-7"></span>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body card-height">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                          <h6>No. of Invoices</h6>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-7">
                          <p>: 2</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-7">
                          <Form.Select aria-label="Default select example">
                            <option>Curency</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                      </div>
                      <div className="row">
                        {/* <form> */}
                        <div className="col-md-3">
                          <div className="mb-3 pt-3">
                            <label>Add Medicine</label>
                            <input
                              type="email"
                              className="login-input"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Add medicine"
                              required
                              maxLength="20"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3 pt-3">
                            <label>Quantity</label>
                            <input
                              type="text"
                              className="login-input position-relative"
                              id="exampleInputPassword1"
                              placeholder="Quantity"
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="mb-3 pt-3">
                            <label>Duration</label>
                            <input
                              type="email"
                              className="login-input"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Duration"
                              required
                              maxLength="20"
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="mb-3 pt-3">
                            <label>Co-pay ( % )</label>
                            <input
                              type="text"
                              className="login-input position-relative"
                              id="exampleInputPassword1"
                              placeholder="%"
                            />
                          </div>
                        </div>
                        <div className="col-md-2 d-flex">
                          <img
                            src={imgDelete}
                            alt="Delete"
                            className="img-delete"
                          />
                          <img src={imgAdd} alt="Add" className="img-delete" />
                        </div>
                        {/* </form> */}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="border-btn px-5 bg_btn text-white font_size float-end"
                          >
                            Save
                          </button>
                          <button className="border-btn px-5 bg-white font_size me-2 btn_cancel float-end">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="head-list my-4">List of Invoice</div>
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <Datatab4 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
