import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Routes,
} from "react-router-dom";
import Login from "./component/login/login";
import SignUp from "./component/SignUp/SignUp";
import RegisterConfirm from "./component/SignUp/RegisterConfirm";

import Pharmacydetails from "./component/Pharmacy Details/Pharmacydetails";
import PatientDetails from "./component/Patient Managment/PatientDetail";
import MedicalReport from "./component/Medical report/MedicalReport";
import Invoice from "./component/Invoice Detail/InvoiceDetail";
import { ToastContainer } from "react-toastify";
import Activation from "./component/Activation/Activation";
import Sidebar from "./component/Navbar/Sidebar";
import Table1 from "./component/Table/Table1";
import Table2 from "./component/Table/Table2";
import PharmacyManagment from "./component/Pharmacy Managment/PharmacyManagment";
// import ReportManagment from "./component/Report Managment/ReportManagment";
// import UploadMedical from "./component/Report Managment/UploadMedical";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Login />} />
        {/* <Route exact path="/signup/confirm" element={<RegisterConfirm />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/activation" element={<Activation />} /> */}
        <Route exact path="/" element={<Sidebar />}>
          <Route exact path="user-managment" element={<PharmacyManagment />} />
          <Route exact path="/table2" element={<Table2 />} />
          <Route
            exact
            path="table1/pharmacy-detail"
            element={<Pharmacydetails />}
          />
          <Route exact path="/patient-detail" element={<PatientDetails />} />
          {/* <Route
            exact
            path="/patient-detail/medical-report"
            element={<MedicalReport />}
          /> */}
          {/* <Route
            exact
            path="/patient-detail/medical-report/invoice"
            element={<Invoice />}
          /> */}
          {/* <Route exact path="/report-managment" element={<ReportManagment />} />
          <Route
            exact
            path="/report-managment/prsecription"
            element={<UploadMedical />}
          /> */}
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
