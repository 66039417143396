import React from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import eyeIcon from "../../assets/ic-hide.png";

function Table3() {
  const columns = [
    {
      name: "Report Name",
      selector: (row) => row.registration,
    },
    {
      name: "Uploaded Date",
      selector: (row) => row.pharmacyname,
    },
    {
      name: "No. of Invoices",
      selector: (row) => row.location,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <Link to="/patient-detail/medical-report">
            <img src={eyeIcon} alt="" />
          </Link>
        );
      },
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Show",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    // selectAllRowsItemText: 'Todos',
  };
  const customStyles = {
    rows: {
      style: {
        minHeight: "46px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "22px",
        // textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "19px",
        textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
  };
  const data = [
    {
      registration: "abc.pdf",
      pharmacyname: "08-05-2023",
      location: "2",
      action: "1982",
    },
    {
      registration: "abc.pdf",
      pharmacyname: "08-05-2023",
      location: "2",
      action: "1982",
    },
  ];
  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
        paginationComponentOptions={paginationComponentOptions}
        pagination
      />
    </>
  );
}
export default Table3;
