/** @format */

import React, { useEffect, useState } from "react";
import Profile from "../../assets/profilepic.jpg";
import { Outlet } from "react-router-dom";
import logoSmall from "../../assets/new-images/Layer 2 LOgo 2.png";
import logo from "../../assets/new-images/Layer 2 LOgo 2.png";

import "./Sidebar.css";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
  FaSignOutAlt,
} from "react-icons/fa";
import { MdArrowRight } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { AiFillSetting, AiOutlineSearch } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import APIURL from "../Utils/api-urls";
const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/user-managment",
      name: "User Management",
      icon: <FaTh />,
    },
    {
      path: "/",
      name: "Logout",
      icon: <FaSignOutAlt />,
    },
    // {
    //   path: "/table2",
    //   name: "Chat Managment",
    //   icon: <FaUserAlt />,
    // },
    // {
    //   path: "/analytics",
    //   name: "Reports",
    //   icon: <FaRegChartBar />,
    // },
    // {
    //   path: "/comment",
    //   name: "Billing Information",
    //   icon: <FaCommentAlt />,
    // },
    // {
    //   path: "/product",
    //   name: "Help and Support",
    //   icon: <FaShoppingBag />,
    // },
    // {
    //     path: "/productList",
    //     name: "Product List",
    //     icon: <FaThList />
    // }
  ];

  const logOut = async () => {
    //  setIsLoading(true);
    //  await axios
    //    .get(`${APIURL.patientList}`, {
    //      headers: {
    //        "Access-Control-Allow-Origin": "*",
    //        Authorization: `Bearer ${localStorage.getItem("auth")}`,
    //      },
    //    })
    //    .then((res) => {
    //      setIsLoading(false);
    //      setPatient(res.data.results);
    //      setfilterdata(res.data.results);
    //    })
    //    .catch((err) => {
    //      setIsLoading(false);
    //    });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-12 col-lg-12 col-xl-12 d-flex p-0">
            <div style={{ width: isOpen ? "20%" : "5%" }} className="sidebar">
              <div className="top_section log-center">
                <h1
                  style={{ display: isOpen ? "block" : "block" }}
                  className="logo"
                >
                  {isOpen ? (
                    <img src={logo} style={{ width: "70%" }} />
                  ) : (
                    <img src={logoSmall} style={{ width: "100%" }} />
                  )}
                </h1>
              </div>
              <NavLink className="link">
                <div className="icon">
                  {/* <FaTh /> */}
                </div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Dashboard
                </div>
              </NavLink>
              <div className="menu">
                <div className="menu-text">Menu</div>
              </div>
              {menuItem.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="link"
                  activeclassname="active"
                >
                  <div className="icon">{item.icon}</div>

                  <div
                    style={{ display: isOpen ? "block" : "none" }}
                    className="link_text"
                  >
                    {item.name}
                  </div>
                </NavLink>
              ))}
            </div>
            <div
              className="toggle-navbar"
              style={{ width: isOpen ? "85%" : "95%" }}
            >
              <div className="card  med-nav">
                <div className="card-body">
                  <div className="row">
                    <div className="col-8 col-sm-8 col-xl-8 col-lg-8 col-md-6">
                      <div className="row">
                        <div className="col-2">
                          <FaBars className="icon-size" onClick={toggle} />
                        </div>
                      </div>
                    </div>
                    <div className="col-4 col-lg-4 col-xl-4 col-md-6 col-sm-4">
                      <div className="row">
                        <div className="col-2">
                          {" "}
                          <IoIosNotifications className="icon-size" />
                        </div>
                        <div className="col-2">
                          {" "}
                          <MdEmail className="icon-size" />
                        </div>
                        <div className="col-6">
                          <p>
                            Hello , John Doe{" "}
                            {/* <img src={Profile} className="profile-pic" /> */}
                          </p>
                        </div>
                        <div className="col-2">
                          <span onClick={logOut}>
                            <AiFillSetting className="icon-size" />{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg_sidebar">
                <div className="">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <>

                <div className="d-flex">



                    <div className="row">
                        <Outlet />
                    </div>


                </div>
            </> */}
    </>
  );
};
export default Sidebar;
