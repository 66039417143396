// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik0yLjUgMEwxIDEuNSAzLjUgNCAxIDYuNSAyLjUgOGw0LTQtNC00eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".border-btn {\n  border: 2px solid #058bc7;\n  border-radius: 6px;\n}\n.head-list {\n  width: 274px;\n  height: 29px;\n  font-family: \"Inter\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 29px;\n  /* identical to box height */\n\n  text-align: center;\n\n  color: #000000;\n}\n.breadcrumb-item + .breadcrumb-item::before {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.bread-high {\n  font-family: \"Inter\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  /*font-size: 24px;\n  line-height: 29px;\n  text-align: center; */\n  color: #000000;\n  text-decoration: none;\n}\n", "",{"version":3,"sources":["webpack://./src/component/Patient Managment/PatientDetail.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,4BAA4B;;EAE5B,kBAAkB;;EAElB,cAAc;AAChB;AACA;EACE,gDAAgO;AAClO;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB;;uBAEqB;EACrB,cAAc;EACd,qBAAqB;AACvB","sourcesContent":[".border-btn {\n  border: 2px solid #058bc7;\n  border-radius: 6px;\n}\n.head-list {\n  width: 274px;\n  height: 29px;\n  font-family: \"Inter\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 29px;\n  /* identical to box height */\n\n  text-align: center;\n\n  color: #000000;\n}\n.breadcrumb-item + .breadcrumb-item::before {\n  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik0yLjUgMEwxIDEuNSAzLjUgNCAxIDYuNSAyLjUgOGw0LTQtNC00eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+);\n}\n\n.bread-high {\n  font-family: \"Inter\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  /*font-size: 24px;\n  line-height: 29px;\n  text-align: center; */\n  color: #000000;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
