import React from "react";
import DataTable from "react-data-table-component";
import eyeIcon from "../../assets/ic-hide.png";

import { Link, useNavigate } from "react-router-dom";

function Table4() {
  const columns = [
    {
      name: "Invoice Number",
      selector: (row) => row.registration,
    },
    {
      name: "Issue Date",
      selector: (row) => row.pharmacyname,
    },
    {
      name: "Patient Name",
      selector: (row) => row.location,
    },
    {
      name: "Payment Status",
      selector: (row) => row.status,
    },
    {
      name: "Payment Mode",
      selector: (row) => row.mode,
    },
    {
      name: "Payment Status",
      selector: (row) => {
        return (
          <Link to="/patient-detail/medical-report/invoice">
            <img src={eyeIcon} alt="" />
          </Link>
        );
      },
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Show",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    // selectAllRowsItemText: 'Todos',
  };
  const customStyles = {
    rows: {
      style: {
        minHeight: "46px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "22px",
        // textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "19px",
        textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
  };
  const data = [
    {
      registration: "# 123456",
      pharmacyname: "08-05-2023",
      location: "Lillian J. Kraemer",
      status: "Settled",
      mode: "Patient pay",
      action: "1982",
    },
  ];
  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
        paginationComponentOptions={paginationComponentOptions}
        pagination
      />
    </>
  );
}
export default Table4;
