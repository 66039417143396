import React from "react";
import Profilepic from "../../assets/profilepic.jpg";
import "./InvoiceDetail.css";
import { Link, useNavigate } from "react-router-dom";

export default function MedicalReport() {
  return (
    <div className="container-fluid">
      <div className="row py-4 px-2">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header bg-white">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item ">
                    <Link className="m-auto link-line" to="/pharmacy-managment">
                      <a className="bread-high">Patient Management</a>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link className="m-auto link-line" to="/patient-detail">
                      Patient Detail
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link
                      className="m-auto link-line"
                      to="/patient-detail/medical-report"
                    >
                      Medical Report/Prescription
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Invoice
                  </li>
                </ol>
              </nav>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="d-mobile d-flex flex-row-reverse flex-sm-row-reverse flex-xs-column-reverse  flex-xs-column-reverse">
                  <div className="p-2 ">
                    <button
                      type="button"
                      className="btn btn_confirm btn_bg  mb-4 text-white px-4 "
                    >
                      Print
                    </button>
                  </div>
                  <div className="p-2 ">
                    <button
                      type="button"
                      className="btn btn-white btn_bdr px-4 font_size btn_confirm"
                    >
                      Save
                    </button>
                  </div>
                  <div className="p-2 ">
                    <button
                      type="button"
                      className="btn btn-white btn_bdr px-4 font_size btn_confirm"
                    >
                      Share
                    </button>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col">
                  <div className="row mx-1 b border-bottom">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 my-2 pt-1">
                      <div className="invoice-text">Invoice</div>
                    </div>
                    <div className="col-xl-6 col-lg-3 col-md-6 col-sm-6 my-2 pt-1">
                      <div className="invoice-date float-end">08-05-2023</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col">
                  <div className="row mx-1 ">
                    <div className="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-6 my-2 pt-1">
                      <div className="from-text">From</div>
                      <div className="my-3">
                        <img
                          src={Profilepic}
                          className="rounded-circle"
                          style={{ width: "17%", height: "50px" }}
                        />
                      </div>
                      <div className="xyz-text pb-2">XYZ Pharmacy</div>
                      <div className="">
                        {" "}
                        <span className="from-add">Location</span>{" "}
                        <span className="from-text">: Bakker Street</span>
                      </div>
                      <div className="mt-2">
                        {" "}
                        <span className="from-add">Phone</span>
                        <span className="from-text">: +244 78547 78547</span>
                      </div>
                      <div className="mt-2">
                        {" "}
                        <span className="from-add">Email</span>
                        <span className="from-text">: xyz@ymail.com</span>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6 my-2 pt-1">
                      <div className="">
                        <div style={{ width: "10%" }}></div>
                        <div className="from-text pt-4">To</div>

                        <div className="xyz-text py-2">Steven Wilkes</div>
                        <div className="">
                          {" "}
                          <span className="from-add">Location</span>{" "}
                          <span className="from-text">: Bakker Street</span>
                        </div>
                        <div className="pt-2">
                          {" "}
                          <span className="from-add">Phone</span>
                          <span className="from-text">: +244 78547 78547</span>
                        </div>
                        <div className="pt-2">
                          {" "}
                          <span className="from-add">Email</span>
                          <span className="from-text">: abc@ymail.com</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <div className="row border bg-light mx-1">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-2">
                      <div className="d-flex justify-content-center py-3">
                        <div className="det-text">Invoice #</div>
                        <div className="det-text1">: 123456789</div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-2">
                      <div className="d-flex justify-content-center py-3">
                        <div className="det-text">Payment Date</div>
                        <div className="det-text1">: 08-05-2023</div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-2">
                      <div className="d-flex justify-content-center py-3">
                        <div className="det-text">Payment Mode</div>
                        <div className="det-text1">: Patient Pay</div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-2">
                      <div className="d-flex justify-content-center py-3">
                        <div className="det-text">Payment Status</div>
                        <div className="det-text1">: Settled</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="head-tab">
                          #
                        </th>
                        <th scope="col" className="head-tab">
                          Medicines
                        </th>
                        <th scope="col" className="head-tab">
                          Serial #
                        </th>
                        <th scope="col" className="head-tab">
                          From
                        </th>
                        <th scope="col" className="head-tab">
                          Quantity
                        </th>
                        <th scope="col" className="head-tab">
                          Unit Cost
                        </th>
                        <th scope="col" className="head-tab">
                          Tax(%)
                        </th>
                        <th scope="col" className="head-tab">
                          Subtotal
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="row-text">1</td>
                        <td className="row-text">Dan P</td>
                        <td className="row-text"> # 123456</td>
                        <td className="row-text"> Tablets</td>
                        <td className="row-text">6</td>
                        <td className="row-text">$ 10</td>
                        <td className="row-text">2 ( % )</td>
                        <td className="row-text">$ 60</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Jacob</td>
                        <td># 123456</td>
                        <td>Tablets</td>
                        <td>6</td>
                        <td>$ 10</td>
                        <td>---</td>
                        <td>$ 60</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="">
                    <div class="p-2 d-flex justify-content-end">
                      {" "}
                      <span className="total-text">Sub-Total Amount</span>{" "}
                      <span className="total-text-color">
                        &nbsp;&nbsp;&nbsp; : $210
                      </span>
                    </div>
                    <div class="p-2 d-flex justify-content-end">
                      {" "}
                      <span className="total-text">Tax ( 18 % )</span>{" "}
                      <span className="total-text-color">
                        &nbsp;&nbsp;&nbsp; : $210
                      </span>
                    </div>
                    <div class=" d-flex justify-content-end">
                      {" "}
                      <div className="border_top">
                        <div className="pt-2">
                          <span className="grand-text">Total</span>{" "}
                          <span className="grand-text-color">
                            &nbsp;&nbsp;&nbsp; : $ 173.00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
